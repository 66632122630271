<template>
  <div class="index-body">
    <div
      class="mian-body"
      v-if="
        sideMsg.allsize > 0 ||
        bookTotal > 0 ||
        Infototal > 0 ||
        picInfoTotal > 0
      "
    >
      <div class="sidebar" :style="{ display: show ? 'block' : '' }">
        <div class="sidebarInner">
          <div class="sidebarTop">
            <i class="el-icon-arrow-left" @click="goback"></i>
            <span>图书分类</span>
          </div>
          <el-menu
            class="el-menu-vertical-demo"
            @open="selectAll"
            unique-opened
            :default-active="defaultactive"
            :default-openeds="defaultOpends"
          >
            <!-- <el-menu-item class="topone" index="3" @click="select()">
              <span>{{ sideMsg.allsize }}）</span>
            </el-menu-item> -->
            <el-submenu index="0" v-if="sideMsg.allsize > 0">
              <template slot="title">
                <span>图书({{ sideMsg.allsize }})</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="'0-' + item.id"
                  v-for="item in sideMsg.directory"
                  @click="select(item.id)"
                  :key="item.id"
                  >{{ item.categoryName }}（{{ item.num }}）</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="1" class="mediaselect" v-if="mediaTotal > 0">
              <template slot="title">
                <span>融媒体({{ mediaTotal }})</span>
              </template>
            </el-submenu>
            <el-submenu index="2" v-if="picInfoTotal + Infototal > 0">
              <template slot="title">
                <span>资讯({{ picInfoTotal + Infototal }})</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="'2-' + item.id"
                  v-for="(item, i) in [
                    { name: '图片资讯', total: picInfoTotal, id: 0 },
                    { name: '其他资讯', total: Infototal, id: 1 },
                  ]"
                  @click="selectInfo(i)"
                  :key="item.name"
                  v-show="item.total > 0"
                  >{{ item.name }}({{ item.total }})</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>

            <!-- <el-menu-item class="topone" index="2" @click="selectInformation()">
              <span>资讯</span>
            </el-menu-item> -->
            <!-- <el-menu-item class="topone" index="2" @click="selectMedia()">
              <span>融媒体</span>
            </el-menu-item> -->
            <!-- <el-submenu index="2">
              <template slot="title">
                <span>融媒体</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="'1-' + item.id"
                  v-for="item in sideMsg.publish"
                  @click="select(item.id, 1)"
                  :key="item.id"
                  >{{ item.categoryName }}（{{ item.num }}）</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu> -->
          </el-menu>

          <div class="buttons">
            <div class="reset" @click="reset">重置</div>
            <div class="confirm" @click="confirm">确认</div>
          </div>
        </div>
      </div>
      <div class="container" v-if="dataList.length > 0">
        <section class="button" v-if="type == 0">
          <div class="select" @click="showSelect">
            <section class="icon">
              <svg
                t="1685339716433"
                class="iconselect"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="6967"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                :width="'.24rem'"
                :height="'.24rem'"
              >
                <path
                  d="M855.342766 151.46262c-6.056949-11.851932-18.984377-19.393699-33.219589-19.393699L101.903901 132.068921c-14.360056 0-27.412326 7.704472-33.396621 19.744693-5.988388 12.015661-3.845585 26.157753 5.520737 36.192294l255.896134 274.308483 0 309.339324c0 12.847609 7.895831 24.602328 20.389376 30.328749l189.116195 86.432535c5.154393 2.371 10.771321 3.515057 16.33913 3.515057 6.541997 0 13.090133-1.607614 18.926048-4.797259 10.718109-5.945409 17.427928-16.503882 17.809621-28.037567l12.957103-396.767536 245.078765-274.90507C859.543438 177.316451 861.425298 163.313529 855.342766 151.46262zM520.773827 804.275693l-117.384477-53.647851L403.38935 483.628836l127.858016 0L520.773827 804.275693zM550.774095 416.986019c-1.963725-0.299829-3.761674-1.090844-5.809309-1.090844L383.519814 415.895175 181.938726 199.803605l562.427506 0L550.774095 416.986019zM685.454494 524.008498l273.392624 0 0 59.759035-273.392624 0 0-59.759035ZM685.454494 654.104485l273.392624 0 0 59.759035-273.392624 0 0-59.759035ZM685.454494 773.618463l273.392624 0 0 59.759035-273.392624 0 0-59.759035Z"
                  p-id="6968"
                ></path>
              </svg>
            </section>
            <span>筛选</span>
          </div>
          <div class="attention" @click="attentionfocus">
            <span>关注度</span>
            <section class="icon">
              <i class="up" :class="attentionborderColor"></i>
              <i class="down" :class="attentionDownborderColor"></i>
            </section>
          </div>
          <div class="time" @click="publishTimefocus">
            <span>出版时间</span>
            <section class="icon">
              <i class="up" :class="publishTimeborderColor"></i>
              <i class="down" :class="publishTimeDownborderColor"></i>
            </section>
          </div>
        </section>
        <div class="search_all_content">
          <ul class="books" ref="Main" v-if="type == 0">
            <li
              v-for="item in dataList"
              class="book"
              :key="item.id"
              @click="goDetail(item.id)"
            >
              <figure>
                <img :src="item.coverUrl" alt="" />
              </figure>
              <div class="books-msg">
                <span
                  v-html="
                    keySign(item.bookName || item.magazineTitle || item.title)
                  "
                >
                </span>
                <span v-show="type == 0">
                  <i
                    >{{ type == 0 ? "作者:" : ""
                    }}<i v-html="keySign(item.author)"></i
                  ></i>
                  <i
                    >{{ type == 0 ? "定价：￥" : ""
                    }}{{ changePrice(item.price) }}</i
                  >
                </span>
                <span
                  >{{
                    type == 1 ? "内容简介" : type == 2 ? "资讯内容" : "内容简介"
                  }}
                  <i
                    @click.stop="buy(item.purchaseLink)"
                    v-if="item.purchaseLink"
                    class="buyicon"
                    >点击购买</i
                  >
                </span>
                <span
                  v-html="
                    keySign(
                      (item.contentIntro && decode(item.contentIntro)) ||
                        (item.infoContent && decode(item.infoContent)) ||
                        (item.content && decode(item.content))
                    )
                  "
                >
                </span>
              </div>
            </li>
          </ul>
          <ul class="books medias" ref="Main" v-if="type == 1">
            <li
              v-for="item in dataList"
              class="book"
              :key="item.id"
              @click="goDetail(item.id)"
            >
              <figure>
                <img
                  :src="item.picUrl || picUrlcheck(item.fileUrl, item)"
                  alt=""
                />
              </figure>
              <div class="books-msg">
                <span
                  v-html="
                    keySign(item.bookName || item.magazineTitle || item.title)
                  "
                ></span>
                <span v-show="item.author || item.price">
                  <i v-if="item.author"
                    >{{ item.author ? "作者:" : "" }}{{ item.author }}</i
                  >
                  <i>{{ item.price ? "价格:" : "" }}{{ item.price }}</i>
                </span>
                <span>{{
                  type == 1 ? "图书简介" : type == 2 ? "资讯内容" : "媒体简介"
                }}</span>
                <span
                  v-html="
                    keySign(
                      (item.contentIntro && decode(item.contentIntro)) ||
                        (item.infoContent && decode(item.infoContent)) ||
                        (item.content && decode(item.content))
                    )
                  "
                ></span>
              </div>
            </li>
          </ul>
          <ul class="books infomation" ref="Main8" v-if="type == 2">
            <li
              v-for="item in dataList"
              class="book"
              :key="item.id"
              @click="goDetail(item.id)"
            >
              <div class="books-title" >
                <span v-html="keySign(item.title)"></span> &nbsp;&nbsp;&nbsp;
                <span v-html="keySign(jiequ(item.infoContent)) "></span>
              </div>
              <div class="books-time">
                {{ item.publishTime && item.publishTime.substring(0, 10) }}
              </div>
            </li>
          </ul>
        </div>

        <div class="pagination" ref="bottom">
          <el-button :disabled="dataFrom.firstPageDisabled" @click="goPageOne">
            首页</el-button
          >
          <el-pagination
            :pager-count="5"
            background
            :current-page="dataFrom.page"
            :page-size="dataFrom.limit"
            layout="prev, pager, next"
            :total="dataFrom.total"
            @current-change="pageCurrentChangeHandle"
          >
          </el-pagination>
          <el-button :disabled="dataFrom.lastPageDisabled" @click="goPageLast"
            >末页</el-button
          >
          <span class="go">跳转</span>

          <input :value="goPage" @input="handleInput" type="text" />
          <span class="page">页</span>
          <el-button @click="go()">跳转</el-button>
        </div>
      </div>
      <div class="out_content" v-if="dataList.length == 0">
        <figure>
          <img src="../../assets/recommend/none.png" alt="" />
        </figure>
        <span>暂无结果,请尝试其他关键词</span>
      </div>
    </div>
    <div
      class="out_content"
      v-if="
        sideMsg.allsize <= 0 &&
        bookTotal <= 0 &&
        Infototal <= 0 &&
        picInfoTotal <= 0
      "
    >
      <!-- <figure>
        <img src="../../assets/recommend/none.png" alt="" />
      </figure> -->
      <span>暂无结果，请尝试其他关键词</span>
    </div>
  </div>
</template>

 
<script>
import Swiper from "swiper";
import music from "../../assets/home/music.png";
export default {
  name: "vue-index",

  data() {
    return {
      msg: "",
      show: false,
      dataFrom: {
        page: 1,
        limit: 4,
        orderField: "", //排序字段
        firstPageDisabled: false,
        lastPageDisabled: false,
        // total: 0,
        order: "",
        status: 1,
      },
      defaultactive: "0",

      type: 0, //0图书  2 融媒体 3资讯
      defaultOpends: ["0"],
      dataList: [],
      total: 0,
      page: 1,

      importTotal: 0,
      newTotal: 0,
      dataFormPages: [],
      goPage: 0,
      sideMsg: {},
      yearsList: [],
      columns: [],
      bookTotal: "",
      mediaTotal: "",
      Total: "",
      PicInfodataList: [],
      InfodataList: [],
      Infototal: "",
      picInfoTotal: "",
      infotype: "",
      music: music,
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      // // 如果需要分页器
      // pagination: '.swiper-pagination',
      // // 如果需要前进后退按钮
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      // // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    });
  },
  async created() {
    // this.defaultOpends = [this.$route.query.publishRange];
    console.log(this.defaultOpends, "this.defaultOpends");

    this.dataFrom.keyword = this.$route.query.keyword;
    // this.selectAll(0);
    // this.getInfo();
    // await this.queryInformation();
    // this.bookTotal = this.dataFrom.total;
    await this.queryInfo();
    this.Infototal = this.dataFrom.total;
    await this.queryPicInfo();
    this.picInfoTotal = this.dataFrom.total;
    await this.queryMedia();
    this.mediaTotal = this.dataFrom.total;
    await this.query();
    this.bookTotal = this.dataFrom.total;
    if (this.bookTotal > 0) {
      this.query();
      this.type = 0;
    } else if (this.mediaTotal > 0) {
      this.defaultOpends = ["1"];
      this.defaultactive = "1";
      this.queryMedia();
      this.type = 1;
    } else if (this.picInfoTotal > 0) {
      this.defaultOpends = ["2"];
      this.defaultactive = "2-0";
      this.queryPicInfo();
      this.type = 2;
      this.infotype = 0
    } else if (this.Infototal > 0) {
      this.defaultOpends = ["2"];
      this.defaultactive = "2-1";
      this.queryInfo();
      this.type = 2;
      this.infotype=1
    }

    console.log(this.bookTotal, this.Total, this.mediaTotal, "bookTotal");
    this.getSide();
    this.scrollWindow();
    // this.queryAllMagazine();
  },
  computed: {
    publishTimeborderColor: function () {
      console.log("1111");
      if (
        this.dataFrom.orderField == "publication_time" &&
        this.dataFrom.order == "asc" &&
        this.dataFrom.order !== ""
      ) {
        return "up_active";
      } else {
        return "";
      }
    },
    publishTimeDownborderColor: function () {
      console.log(222);
      if (
        this.dataFrom.orderField == "publication_time" &&
        this.dataFrom.order == "desc" &&
        this.dataFrom.order !== ""
      ) {
        return "down_active";
      } else {
        return "";
      }
    },
    attentionDownborderColor: function () {
      console.log(222);
      if (
        this.dataFrom.orderField == "page_view" &&
        this.dataFrom.order == "desc" &&
        this.dataFrom.order !== ""
      ) {
        return "down_active";
      } else {
        return "";
      }
    },
    attentionborderColor: function () {
      console.log(222);
      if (
        this.dataFrom.orderField == "page_view" &&
        this.dataFrom.order == "asc" &&
        this.dataFrom.order !== ""
      ) {
        return "up_active";
      } else {
        return "";
      }
    },
  },
  methods: {
    buy(url) {
      // window.location.href = url;
      if (!url) {
        return;
      }
      window.open(url);
    },
    goPageOne() {
      this.dataFrom.page = 1;
      if (this.type == 0) {
        this.query();
      } else if (this.type == 1) {
        this.queryMedia();
      } else if (this.type == 2) {
        console.log(this.defaultactive, "defaultactive");
        // this.queryPicInfo();
        //  this.queryInfo();
        this.selectInfo(this.infotype);
      }
    },
    goPageLast() {
      this.dataFrom.page = Math.ceil(this.dataFrom.total / this.dataFrom.limit);
      if (this.type == 0) {
        this.query();
      } else if (this.type == 1) {
        this.queryMedia();
      } else if (this.type == 2) {
        console.log(this.defaultactive, "defaultactive");
        // this.queryPicInfo();
        //  this.queryInfo();
        this.selectInfo(this.infotype);
      }
    },
    keySign(title) {
      let s = this.$route.query.keyword; // 搜索框的值(您要标红的关键字)
      var str = title; // 列表标题(原文本)

      // 去除中间空格且字符之间用逗号隔开
      let inputvalue = s.replace(new RegExp(/\s+/g), ","); // 把空格分开的字符串转换成以逗号分割
      let keyWordArr2 = inputvalue.split(","); // 把字符串分割转换成数组(方便截取)
      // 判断文本段落(原文本)是否为空
      if (str && str != "") {
        // 遍历分割后的字符串
        keyWordArr2.forEach((e) => {
          let regStr = "" + `(${e})`;
          let reg = new RegExp(regStr, "g");
          // 如果匹配成功则抛出关键字DOM
          // TIPS: 这块您可以自定义标签可根据您的需求自定义样式
          str = str.replace(
            reg,
            '<i style="color:red;font-weight: bold;">' + e + "</i>"
          ); // 改变搜索关键字颜色为红色
        });
      }
      return str;
    },
    publishTimefocus() {
      if (this.dataFrom.orderField !== "publication_time") {
        this.dataFrom.orderField = "publication_time";
        this.dataFrom.order = "";
      }

      if (this.dataFrom.order == "asc") {
        this.dataFrom.order = "desc";
      } else if (this.dataFrom.order == "desc") {
        this.dataFrom.order = "";
      } else {
        this.dataFrom.order = "asc";
      }
      this.query();
      console.log(this.dataFrom.order);
    },
    selectMedia() {
      this.type = 1;
      this.dataFrom.page = 1;
      this.queryMedia();
    },
    async queryMedia() {
      console.log(this.dataFrom, "resdata");
      this.dataFrom.limit = 4;
      const { data: res } = await this.$http.get("/convergencemedia/homePage", {
        params: {
          ...this.dataFrom,
        },
      });
      this.type = 1;
      // .then(({ data: res }) => {
      if (res.code !== 0) {
        this.dataList = [];
        this.dataFrom.total = 0;
        return this.$message.error(res.msg);
      }
      this.dataList = res.data.list;

      this.dataFrom.total = res.data.total;
      // })
      // .catch(() => {});
    },
    attentionfocus() {
      if (this.dataFrom.orderField !== "page_view") {
        this.dataFrom.orderField = "page_view";
        this.dataFrom.order = "";
      }

      if (this.dataFrom.order == "asc") {
        this.dataFrom.order = "desc";
      } else if (this.dataFrom.order == "desc") {
        this.dataFrom.order = "";
      } else {
        this.dataFrom.order = "asc";
      }
      this.query();
    },
    goDetail(id) {
      if (this.type == 0) {
        // this.$router.push({
        //   path: "recommend/bookdetail",
        //   query: {
        //     id: id,
        //   },
        // });
        let url = this.$router.resolve({
          path: "recommend/bookdetail",
          query: {
            id: id,
          },
        });
        console.log(url, "preferredPages/order");
        window.open(url.href, "_blank");
      } else if (this.type == 2) {
        console.log(this.infotype,'this.infotype');
        if (this.infotype == 0) {
          // this.$router.push({
          //   path: "information/picinformationdetail",
          //   query: {
          //     id: id,
          //   },
          // });
          let url = this.$router.resolve({
            path: "information/picinformationdetail",
            query: {
              id: id,
            },
          });
          console.log(url, "preferredPages/order");
          window.open(url.href, "_blank");
        } else {
          // this.$router.push({
          //   path: "information/informationdetail",
          //   query: {
          //     id: id,
          //   },
          // });
          let url = this.$router.resolve({
            path: "information/informationdetail",
            query: {
              id: id,
            },
          });
          console.log(url, "preferredPages/order");
          window.open(url.href, "_blank");
        }
      } else if (this.type == 1) {
        // this.$router.push({
        //   path: "/convergencemedia/media",
        //   query: { id: id },
        // });
        let url = this.$router.resolve({
          path: "/convergencemedia/media",
          query: { id: id },
        });
        console.log(url, "preferredPages/order");
        window.open(url.href, "_blank");
      }
    },
    selectAll(msg, page) {
      console.log(msg, page, "msgggg");

      this.dataFrom.page = typeof page === "number" ? page : 1;

      if (msg == 1) {
        this.dataFrom.limit = 4;

        this.queryMedia();
      } else if (msg == 2) {
        // this.dataFrom.limit = 9;
        // this.selectInfo(this.infotype);
      } else {
        this.dataFrom.category = "";
        // this.query();
      }
    },
    selectAll1(msg, page) {
      console.log(msg, page, "msgggg");

      this.dataFrom.page = typeof page === "number" ? page : 1;

      if (msg == 1) {
        this.dataFrom.limit = 4;

        this.queryMedia();
      } else if (msg == 2) {
        this.dataFrom.limit = 9;
        this.selectInfo(this.infotype);
      } else {
        this.dataFrom.category = "";
        this.query();
      }
    },

    async getSide() {
      let { data: res } = await this.$http.get(
        "/books/getAllBooksInfoByConditionKeyword",
        {
          params: { status: 1, keyword: this.$route.query.keyword },
        }
      );

      if (res.code !== 0) {
        this.dataList = [];
        this.dataFrom.total = 0;
        return this.$message.error(res.msg);
      }

      this.sideMsg = res.data;
    },
  jiequ(str){

var re1 = new RegExp("<.+?>","g")

var msg = str.replace(re1,'')

    let index1=msg.indexOf(this.$route.query.keyword);
    let arr1=msg.substring(index1-20,index1+15);
    return arr1
  },
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      var test = base64 && base64.replaceAll(/<[^>]+>/g, "");
      return test;
    },
    changePrice(price) {
      let num = price + "";
      if (!/\./.test(num)) {
        num += ".00";
      }
      var arrayNum = num.split(".");
      if (arrayNum[1].length < 2) {
        num += "0";
      }
      return num;
    },
    showSelect() {
      this.show = !this.show;
    },

    handleInput(value) {
      this.goPage = +value.data;
    },
    go() {
      this.dataFrom.page = this.goPage;
      this.query();
    },
    goback() {
      this.show = !this.show;
    },
    reset() {
      this.dataFrom = {
        page: 1,
        limit: 6,
        orderField: "", //排序字段
        firstPageDisabled: false,
        lastPageDisabled: false,
        total: 0,
        order: "",
        status: 1,
      };
      this.query();
      this.show = !this.show;
    },

    confirm() {
      this.query();

      this.show = !this.show;
    },
    selectInfo(index) {
      this.dataFrom.limit = 9;

      this.infotype = index;
      if (index == 0) {
        this.queryPicInfo();
      } else {
        this.queryInfo();
      }

      this.type = 2;
    },
    async queryPicInfo() {
      const { data: res } = await this.$http.get("/picinformation/homePage", {
        params: {
          status: 1,
          ...this.dataFrom,
          // title: this.dataFrom.keyword,
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.dataFrom.total = 0;
        return this.$message.error(res.msg);
      }
      this.dataList = res.data.list;

      this.dataFrom.total = res.data.total;
    },
    async queryInfo() {
      const { data: res } = await this.$http.get("/information/homePage", {
        params: {
          status: 1,

          ...this.dataFrom,
          // title: this.dataFrom.keyword,
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.dataFrom.total = 0;
        return this.$message.error(res.msg);
      }
      this.dataList = res.data.list;

      this.dataFrom.total = res.data.total;
    },
    picUrlcheck(Url, item) {
      let URL = JSON.parse(Url)[0];
      let urlcheck = URL.response.substring(URL.response.lastIndexOf(".") + 1);
      if (urlcheck == "mp3") {
        return this.music;
      } else {
        return item.firstFramePicUrl;
      }
    },

    select(type, publishRange) {
      this.dataFrom.category = type;
      this.dataFrom.publishRange = publishRange;
      this.dataFrom.page = 1;

      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        //显示移动端的代码
      } else {
        this.query();
      }
    },
    pageCurrentChangeHandle(index) {
      console.log("1111", index);
      this.dataFrom.page = +index;
      this.selectAll1(this.type, index);
      console.log("1112", index);
    },
    selectYears(list) {
      this.yearsList = [];
      this.columns = [];
      let obj = {};
      this.yearsList = list.reduce(function (item, next) {
        obj[next.year] ? "" : (obj[next.year] = true && item.push(next));
        return item;
      }, []);
      this.yearsList.sort((a, b) => b.year - a.year);
      this.yearsList.forEach((year) => {
        this.columns.push(year.year);
      });
    },
    async queryAllMagazine() {
      console.log("resdata");

      const { data: res } = await this.$http.get("/magazine/homePage", {
        params: {
          status: 1,
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.dataFrom.total = 0;
        return this.$message.error(res.msg);
      }
      this.selectYears(res.data.list);
    },
    selectInformation() {
      this.type = 2;
      this.dataFrom.page = 1;
      this.queryInformation();
    },
    async queryInformation() {
      const { data: res } = await this.$http.get("/information/homePage", {
        params: {
          ...this.dataFrom,
        },
      });
      // .then(({ data: res }) => {
      if (res.code !== 0) {
        this.dataList = [];
        this.dataFrom.total = 0;
        return this.$message.error(res.msg);
      }
      this.dataList = res.data.list;

      this.dataFrom.total = res.data.total;
      // })
      // .catch(() => {});
    },
    async queryMagazine() {
      console.log("resdata");

      const { data: res } = await this.$http.get("/magazine/homePage", {
        params: {
          ...this.dataFrom,
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.dataFrom.total = 0;
        return this.$message.error(res.msg);
      }
      this.dataList = res.data.list;
      this.dataFrom.total = res.data.total;
    },
    async query() {
      this.dataFrom.limit = 4;

      let { data: res } = await this.$http.get("/books/homePage", {
        params: {
          ...this.dataFrom,
        },
      });
      this.type = 0;

      if (res.code !== 0) {
        this.dataList = [];
        this.dataFrom.total = 0;
        return this.$message.error(res.msg);
      }
      this.dataList = res.data.list;
      this.dataFrom.total = res.data.total;
    },
    getInfo() {
      //   this.$http
      //     .get(`/h5sysmenu/bookpage/`, { params: {} })
      //     .then(({ data: res }) => {
      //       if (res.code !== 0) {
      //         return this.$message.sel(res.msg);
      //       }
      //       console.log(res.data, "getInfo");
      //       this.dataFormPages = res.data.list;
      //     })
      //     .catch(() => {});
    },
    scrollWindow() {
      window.addEventListener("scroll", () => {
        if (
          navigator.userAgent.match(
            /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
          )
        ) {
          //显示移动端的代码
          this.getMainBox();
        }
      });
    },
    async getMainBox() {
      let Main = this.$refs.Main8;
      let bottom = this.$refs.bottom; //底部的相关DOM
      // console.log(Main.offsetTop);      //父盒子距离浏览器顶部的高度
      // console.log(window.innerHeight);  //浏览器的高度，即页面窗口的高度
      if (Main && Main.clientHeight) {
        return;
      } //父盒子的高度(加上padding和margin)
      console.log(window.scrollY); //浏览器右边的滚动条滚动距离
      if (
        Math.abs(
          Main.clientHeight -
            window.innerHeight -
            (window.scrollY - Main.offsetTop - bottom.clientHeight)
        ) <= 10
      ) {
        console.log("我滑动到底部了");
        this.dataFrom.page += 1;
        let { data: res } = await this.$http.get("/books/homePage", {
          params: {
            ...this.dataFrom,
          },
        });
        this.dataList = this.dataList.concat(res.data.list);
        console.log(this.dataList, res.data.list, "this.dataLis");

        // alert('12323132')
        //这里在运用在获取新的相关数据即可
      }
    },
  },
  watch: {
    dataFrom: {
      handler(newVal) {
        let pages = Math.ceil(newVal.total / newVal.limit);
        this.goPage = this.dataFrom.page;
        if (pages === 0) {
          // 数据(totalResult)为0
          this.dataFrom.firstPageDisabled = true; // 首页按钮是否禁用
          this.dataFrom.lastPageDisabled = true; // 末页按钮是否禁用
        } else {
          this.dataFrom.firstPageDisabled = newVal.page === 1;
          this.dataFrom.lastPageDisabled = newVal.page === pages;
          console.log(
            pages,
            this.dataFrom.firstPageDisabled,
            this.dataFrom.lastPageDisabled,
            "pages"
          );
        }
      },
      // 一进页面就执行
      immediate: true,
      deep: true,
    },

    $route(to, from) {
      location.reload();
      console.log("当前页面路由2：" + to.path);
      console.log("上一个路由2：" + from);
    },
  },
};
</script>

<style lang="scss" scoped>
.buybutton {
  display: none;
}

.out_content {
  height: 7.24rem;
  // width: 10.56rem;
  display: flex;

  align-items: center;
  flex-direction: column;
  justify-content: center;

  figure {
    margin-top: 1.58rem;
    width: 1rem;
    height: 1rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  span {
    margin-top: 0.32rem;
    font-size: 0.22rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 0.2rem;
  }
}
.banner {
  margin-top: 0.56rem;

  img {
    width: 100%;
    height: 100%;
  }
}
.mian-body {
  position: relative;

  height: 9.1rem;
  padding: 0 0.64rem;
  padding-top: 0.6rem;
  margin-bottom: 0.6rem;

  .topone {
    padding-left: 0.32rem !important;
    // height: 0.56rem;
    margin-bottom: 0.08rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    font-size: 0.16rem;
  }
  .topone:hover {
    // background: #ffffff;
  }
  .topone.is-active {
    // background: #ffffff;
  }
  .topone.is-active::after {
    // display: none;
  }
}
.mian-body {
  display: flex;
  justify-content: space-between;
}

.sidebar {
  width: 2.4rem;
  height: 9.1rem;
  background: #ffffff;
  overflow: hidden;
  .buttons {
    display: none;
  }
  .sidebarTop {
    display: none;
  }
}

.container {
  position: relative;
  box-sizing: border-box;
  padding: 0.32rem;
  padding-right: 0.66rem;
  padding-top: 0.32rem;
  padding-bottom: 0.66rem;
  width: 10.56rem;
  height: 9.1rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  .button {
    width: 2.4rem;
    display: flex;
    justify-content: space-between;

    cursor: pointer;

    div {
      display: flex;
      justify-content: center;

      font-size: 0.16rem;

      color: rgba(0, 0, 0, 0.85);
      width: 1.12rem;
      height: 0.4rem;
      background: rgba(238, 238, 238, 0.5);
      border-radius: 0.04rem;
      line-height: 0.4rem;
      position: relative;
      span {
        font-size: 0.16rem;
      }
    }
    .icon {
      display: flex;
      flex-direction: column;
      margin-left: 0.08rem;
      box-sizing: border-box;

      justify-content: center;
    }
    .down {
      width: 0;
      border: 0.05rem solid;
      border-top: 0.07rem solid;
      border-bottom: 0.07rem solid;
      border-color: rgba(0, 0, 0, 0.25) transparent transparent transparent;
    }
    .down_active {
      border-color: var(--backgroundColor) transparent transparent transparent;
    }
    .up {
      width: 0;
      border: 0.05rem solid;
      border-top: 0.07rem solid;
      border-bottom: 0.07rem solid;
      border-color: transparent transparent rgba(0, 0, 0, 0.25) transparent;
      margin-bottom: 0.02rem;
    }
    .up_active {
      border-color: transparent transparent var(--backgroundColor) transparent;
    }
    .select {
      display: none;
    }
  }
}
.mediaselect {
  /deep/ .el-submenu__icon-arrow {
    display: none;
  }
}

/deep/ .el-icon-arrow-down:before {
  color: var(--backgroundColor);
}
/deep/ .el-menu-item {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.48rem;
  line-height: 0.48rem;
  padding-left: 0.24rem !important;
  position: relative;
  margin-bottom: 0.08rem;
  font-size: 0.12rem;

  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}
/deep/ .el-submenu__title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.56rem;
  line-height: 0.48rem;
  padding-left: 0.32rem !important;
  font-size: 0.14rem;
}
/deep/.el-menu-item:hover {
  background: #eeeeee;
}
/deep/.el-submenu__title:hover {
  background: #ffffff;
}
/deep/ .el-menu-item.is-active {
  background: rgba(195, 40, 43, 0.1);
}
/deep/ .el-menu-item-group__title {
  padding: 0;
  height: 0.08rem;
}
/deep/ .is-opened .el-submenu__title {
  background: rgba(195, 40, 43, 0.1);
  &::after {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: "";
    width: 0.04rem;
    height: 0.56rem;
    background: #c3282b;
  }
}
// /deep/ .is-opened .el-submenu__title::after{

// }
.el-menu-item.is-active::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "";
  width: 0.04rem;
  height: 0.48rem;
  background: #c3282b;
}

.books {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    margin-top: 0.3rem;
    display: flex;
  }
  figure {
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border: 0.01rem solid #eeeeee;
    img {
      height: 90%;
    }
  }
  .books-msg {
    display: flex;
    flex-direction: column;
    margin-left: 0.23rem;
    .buyiconyidong {
      display: none;
    }
    .buyicon {
      margin-left: 0.53rem;
      width: 0.9rem;
      height: 0.31rem;
      background: #b20103;
      border-radius: 0.02rem;
      display: inline-block;
      font-size: 0.12rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.31rem;
      text-align: center;
    }
    span:nth-child(1) {
      line-height: 0.24rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      font-size: 0.16rem;
      margin-top: 0.12rem;
      overflow: hidden; // 溢出隐藏
      white-space: nowrap; // 强制一行
      text-overflow: ellipsis; // 文字溢出显示省略号
      width: 8.03rem;
    }
    span:nth-child(2) {
      font-size: 0.14rem;
      margin-top: 0.08rem;
      line-height: 0.22rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      i {
        margin-right: 0.15rem;
      }
    }
    span:nth-child(3) {
      line-height: 0.24rem;
      margin-top: 0.16rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      font-size: 0.16rem;
      margin-top: 0.12rem;
    }
    span:nth-child(4) {
      margin-top: 0.08rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.22rem;
      width: 8.28rem;
      height: 0.44rem;

      font-size: 0.14rem;
      display: -webkit-box;
      overflow: hidden;
      white-space: normal !important;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.medias {
  .book {
    figure {
      width: 2.3rem;
      height: 1.48rem;
      img {
        width: 100%;
        height: 100%;
      }
      &::after {
        position: absolute;
        left: 50%;
        top: 50%;
        content: "";
        width: 0.23rem;
        height: 0.23rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAuCAYAAABXuSs3AAAAAXNSR0IArs4c6QAABQFJREFUaEPVmXmsXVMUxn9fjWkNQYgxGkGChJCmFP8g5qgh0UbaaEIpKQ2KpDGUVgwVtDWEShtDSZqghiAkIhFUNRXzEDTGEIqYYvbJOvZ5Tm/vvee8e27vu3Zy85J3z177t9fdZ621vyX+p0Pd5ra9MbAVsAHwjaQfu71G2KsFbntzYH/gAGB7YEtgvQAGfgO2BjYCvk3/exlYJunzupsZNLjt9YFjgYOAnYGVwPPApwEo6ecilO0N04ZiEwenTf4CxCYekPRDJ5uoDG57WFp4EvA+8DTwuqQ/B7uw7d3Sxo8AHgYekRS/UOVRCdz2rsC5wF/AIklvVl6hzYO2twVOBfYC7pL0bFW7peC24+c9G7hV0otVDQ/mueSY6cBy4G5JLpvfFtz2BOAwYJakj8qM1fne9gjgEuBX4CpJf7ez1xLc9nHA0cAMSd/Xgao613ZEpAeB8Ph4Sb+3mtsU3PbxwGTgZEnhgZ4O2xemPHBtq2OzFrjtfYHzgemSvu4pcVoshdyrgdck3deMYQ1w29sB1wMzJX04FND5munM35SizVpBoRH8PGC1pMVDCV2A3weYGlFNUoTigTEAbntkvM3AGZIis/XFsD07lQlPtAKfCbwi6bG+IP7vvO8CXJkcOhAoMo+nFHwRMKVK8O/1xmzHEf5Y0tJ87Rz8FGC4pIW9hqqynu2oQMdKigSVjRx8HrBA0ltVDPX6mVRhRsA4TdJPGbjtKDcDfEKdY2J7OLCZpC/XxcZsXwq8kBdiAX4UsLuk+XUWtH1mqtMfAu6X9Ecde41zbR8CjJIUeSbzeJzvYa0yVNXFbUfZG/V1jLhUzJP0XtX5Zc/Zjpg+Lj/nAR4BfpWkJ8smt/u+ATwLVnFBAO5tVyxVXdP2TlE9Sjor9/jlwFOSohbueDQBz219Acyve/lIJUBcYsbn4HOT4VUdU/+bC6YBh7ewEd6fLOmrmmssASZFxRpH5Zr0Mr1R02gr8IB+PIXb0ptNK4Z0540X/6S4ZAT4xcBLkp6rCV58Obt6TLIXxg7pY66kuKNmUSUuDCHcDKTTTjbQcMbDs48C93TjxUzgoQxMlRTpPwM/MXSPuum+AP5ZCoXvduKANkcl0v6Rkmbl4KNTHRCZqeNhew9gz/B0t5NP8nhEkxGSFuXgoTRFHXD6utL5OvZGYaLtm4HbJL2TgafdzAhJTNIz3Vik2zZsbwPENW5iXk/l4IeGpicpLqh9N2yPBUYW66kcfFPgDmCapNX9RJ7i9y0pD7yasxXvnOPSrub0GfgxwBhJlxW5iuChYy8AZkv6oB/gU5PgziSXrFGSNMoTIbuNbtzdUG3CdoTAUB/mNF5yGsFDA48AH2VuFi+HatiOLscU4AJJ3zVyNJPgNgFuBJYMVXhMqsMV6Yg0PbatRM8d4+cBbpAUrZKejRSzQ224TlK0aJqOdjLzDsDtKQz1RCRKno7So7Q7USbsB3woXMvrFmFlP5ntA4FzIkNKWlH2fJVWSnQKoiSIZ8MT0bjq2rAdPdGJwN4htUn6pIrxUvAwkrJX3OBDEXgbWFg3wyaRJ+yFPBKC5tJc7OkaeG4oLXYCEJ+ot5el4qxSqyXN3y/1OkelXudiSdHAHdSo5PFGi7YjZAbAmPQ3QlbWoC18on8TxyCuXPE3FLOYE1pLKAor6qheHYEXN5LaHiHWREt8iwJodKCLG4k2+crGzvOg3Fx4+B/n4/8+uwEcZQAAAABJRU5ErkJggg==)
          no-repeat center;
        background-size: 100% 100%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .books-msg {
    span:nth-child(2) {
      display: none;
    }
    span:nth-child(3) {
      display: none;
    }
    span:nth-child(4) {
      height: 0.88rem;
      -webkit-line-clamp: 4;
      width: 7.28rem;
    }
  }
}
.infomation {
  li {
    width: 9.92rem;
    height: 0.72rem;
    line-height: 0.72rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.16rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    border-bottom: 0.01rem solid #eeeeee;
    margin-top: 0;
    .books-title {
      width: 8.5rem;
      overflow: hidden; // 溢出隐藏
      white-space: nowrap; // 强制一行
      text-overflow: ellipsis; // 文字溢出显示省略号
    }
  }
}
.pagination {
  position: absolute;
  right: 0.32rem;
  //   margin-top: 0.16rem;
  bottom: 0.32rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
    margin-right: 0.16rem;
  }
  .go {
    margin: 0 0.08rem;
  }
  .page {
    margin-left: 0.14rem;
  }
  input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    text-align: center;
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
  input:focus-visible {
    outline: none;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  // /deep/ .el-input__inner {
  //   width: 0.32rem;
  //   height: 0.32rem;
  //   background: #ffffff;
  //   border-radius: 0.04rem;
  //   border: 0.01rem solid #eeeeee;
  // }

  /deep/.el-button {
    width: 0.6rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    // line-height: .02rem;

    padding: 0;
  }
  /deep/.el-button span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }

  /deep/ .el-pagination .btn-prev .el-icon {
    content: "首页";
      font-weight: 500;
  }
  /deep/ .el-icon-arrow-left:before {
      content: "上一页";                font-weight: 500;

    font-size: 0.14rem;
  }
  /deep/ .el-icon-arrow-right::before {
        content: "下一页";            font-weight: 500;
    font-size: 0.14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
  }
  /deep/.el-button span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
  }
  /deep/ .el-button.is-disabled span {
    color: #c0c4cc;
  }
  .el-pagination .btn-prev .el-icon {
    content: "首页";
      font-weight: 500;
  }
  /deep/ .el-icon-arrow-left:before {
      content: "上一页";                font-weight: 500;
         font-size: .14rem;
  }
  /deep/ .el-icon-arrow-right::before {
        content: "下一页";            font-weight: 500;
         font-size: .14rem;
  }

  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .btn-next {
    width: 0.74rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: var(--backgroundColor);
  }
  /deep/ .el-pagination.is-background .el-pager li {
    width: 0.32rem;
    height: 0.32rem;
    line-height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.14rem;
  }
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: var(--backgroundColor);
    color: #ffffff;
  }
}

.book {
  cursor: pointer;
}

@media (max-width: 768px) {
  .index-body {
    margin-top: 0;

    padding-left: 0;

    .mian-body {
      top: 0;
      padding: 0;
      margin-bottom: 0;
      height: auto;
      .container {
        padding: 0;
        width: 3.75rem;
        height: auto;
        .button {
          width: 3.75rem;
          height: 0.56rem;
          background: #ffffff;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          div {
            background: #ffffff;
            width: 0.96rem;
            justify-content: flex-end;
            span {
              font-size: 0.14rem;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          .select {
            display: flex;
            flex-grow: 1;
            justify-content: flex-start;
            margin-left: 0.16rem;
            span {
              margin-left: 0.08rem;
            }
            svg {
              fill: var(--backgroundColor);
            }
          }
          .time {
            margin-right: 0.22rem;
          }
          svg {
            width: 100 !important;
            height: 100 !important;
          }
        }
        .books {
          width: 100%;
          li {
            margin: 0;
            margin-bottom: 0.14rem;
            margin-left: 0.15rem;
            figure {
              width: 0.88rem;
              height: 1.22rem;
            }
            .books-msg {
              span:nth-child(1) {
                margin: 0;
                font-size: 0.16rem;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.9);
                line-height: 0.24rem;
                margin-bottom: 0.1rem;
              }
              span:nth-child(2) {
                margin: 0;
                display: flex;
                i {
                  margin-right: 0.08rem;
                  display: inline-block;
                  padding: 0 0.08rem;
                  height: 0.24rem;
                  line-height: 0.24rem;

                  background: rgba(255, 244, 244, 1);
                  border-radius: 0.02rem;
                  color: rgba(195, 40, 43, 1);
                }
                i:nth-child(1) {
                  // margin-right: .02rem;
                  background: #f3e3e3;
                }
              }
              span:nth-child(3) {
                display: none;
              }
              span:nth-child(4) {
                margin-top: 0.12rem;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.45);
                line-height: 0.22rem;
                width: 2.37rem;

                height: 0.4rem;
                font-size: 0.13rem;
                display: -webkit-box;
                overflow: hidden;
                white-space: normal !important;
                text-overflow: ellipsis;
                word-wrap: break-word;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
      .sidebar {
        display: none;
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;

        background: rgba(0, 0, 0, 0.65);
        z-index: 9;
        .sidebarInner {
          width: 3rem;
          height: 100vh;
          background: #ffffff;
          border-radius: 0rem 0.16rem 0.16rem 0rem;
          overflow: hidden;
        }
        .sidebarTop {
          margin-top: 0.6rem;
          margin-bottom: 0.07rem;
          margin-left: 0.3rem;
          display: flex;
          span {
            // width: 0.64rem;
            height: 0.24rem;
            font-size: 0.16rem;

            font-weight: 500;
            color: rgba(0, 0, 0, 0.9);
            line-height: 0.24rem;
            margin-left: 0.62rem;
          }
        }

        .buttons {
          margin: 0 0.56rem;
          margin-top: 0.32rem;
          display: flex;
          justify-content: space-between;
          .reset {
            width: 0.86rem;
            height: 0.32rem;
            background: #fff4f4;
            border-radius: 0.04rem;

            font-size: 0.14rem;

            font-weight: 400;
            color: #c3282b;
            line-height: 0.32rem;
            text-align: center;
          }
          .confirm {
            width: 0.86rem;
            height: 0.32rem;
            background: #c3282b;
            border-radius: 0.04rem;
            text-align: center;
            font-size: 0.14rem;

            font-weight: 400;
            color: #ffffff;
            line-height: 0.32rem;
          }
        }
      }
    }

    .banner {
      display: none;
    }
  }
  /deep/ .el-icon-arrow-left::before {
    content: "\e6de";
    color: var(--backgroundColor);
    font-size: 0.2rem;
    font-weight: 600;
  }
  /deep/ .el-submenu__title {
    height: 0.48rem;
  }
  .pagination {
    display: none;
  }
}
</style>